import React from 'react';
import styled from 'styled-components';
import { medias } from '../../utils/mediaQueries';
import moment from 'moment';
import { useFormState } from 'react-use-form-state';
import { toast } from 'react-toastify';
import theme from '../../styles/theme';
import { PageWrapper, PageTitle } from '../../components/pageComponents';
import {
  CardContainer,
  MaterialInput,
  MaterialDatePicker,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialSelect,
  Button,
  Loader
} from '../../components';
import { FormControl } from '@material-ui/core';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { siteContent } from '../../services/api';
import { CheckListIcon } from '../../components/icons';
import phoneMask from '../../utils/phoneMask';
import { isBrowser } from '../../services/checkSession';


const initialFormState = {
  name: '',
  email: '',
  phone: '',
  city: null,
  uf: null,
  codRegion: 0,
  codSegment: 0,
};


const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 100%;
  }
  .half {
    flex-basis: 45%;
  }
  button[type='submit'] {
    background-color: ${theme.colors.text_heading};
    color: white;
    padding: 0.5em 1em;
    margin-top: 2rem;
  }
`;

const InviteButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin-top: 1em;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const SuccessWrapper = styled(PageWrapper)`
  text-align: center;
  button {
    /* color: ${theme.colors.text_heading} !important; */
    margin: 1rem auto 0;
  }
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 100%;
`;

const IndicacaoNoivoPage = ({ location, pageContext }) => {
  const [formState, { text, email, tel, raw }] = useFormState(
    initialFormState,
    {
      withIds: true,
    }
  );
  const data = useStaticQuery(graphql`
    {
      regions: allRegion {
        edges {
          node {
            Regiao
            codRegiao
          }
        }
      }
      segments: allSegments {
        edges {
          node {
            Segmento
            codSegmento
          }
        }
      }
    }
  `);
  const isLoggedIn = isBrowser() && !!localStorage.getItem('token');

  const [weddingDate, setDate] = React.useState(null);
  const [dateUndefined, setDateUndefined] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [segments, setSegments] = React.useState(data.segments.edges);
  const supplierId = pageContext
  ? pageContext.id
  : location.state.id;
  
  const {
    name,
    email: _email,
    phone,
    codRegion,
    codSegment
  } = formState.values;

  const isClickAvaliable =
    name &&
    _email &&
    phone &&
    (weddingDate || dateUndefined) &&
    codRegion !== 0 &&
    codSegment !== 0;

  React.useEffect(() => {
    const getSegments = async (codFornecedor) => {
      const s = await siteContent.getSegments(codFornecedor);
      if (s.ok && s.data.segmentCodes) {
        setSegments(
          segments.filter(({ node }) =>
            s.data.segmentCodes.includes(node.codSegmento)
          )
        );
      }
    };
    if (isLoggedIn) {
      navigate('/app');
    }
    getSegments(supplierId);
  }, [supplierId, segments,setSegments, isLoggedIn]);

  const handleSubmit = async () => {
    setLoading(true);
    const { codRegion, codSegment, email, phone, name } = formState.values;
    if (!codRegion || !codSegment || !email || !phone || !name) {
      toast.error('Preencha todos os campos!');
      setLoading(false);
      return;
    }

    const res = await siteContent.JoinBrideFromIndication({
      isIndicating: 1,
      codFornecedor: Number(supplierId),
      ...formState.values,
      weddingDate: dateUndefined
        ? null
        : moment(weddingDate).format('YYYY-MM-DD'),
    });

    res.ok
      ? setSuccess(true)
      : res.status === 412
      ? toast.error('Email já cadastrado no sistema!')
      : toast.error('Houve um problema com seu envio, tente novamente!');

    setLoading(false);
  };

  /*const resetForm = () => {
    formState.clear();
    setDate(null);
    setSuccess(false);
  };*/

  if (success) {
    return (
      <SuccessWrapper>
        <PageTitle>Cadastro realizado com sucesso!</PageTitle>
        <p>
          <CheckListIcon /> Bem-vinda(o) à Vestidas de Branco,{' '}
          {formState.values.name}!
        </p>
      </SuccessWrapper>
    );
  }

  return (
    <PageWrapper>
      <PageTitle>Realizar cadastro</PageTitle>
      <CardContainer>
        <Form onSubmit={handleSubmit}>
          <MaterialInput
            disabled={loading}
            {...text('name')}
            required
            label="Nome"
          />
          <MaterialInput
            disabled={loading}
            {...email('email')}
            required
            label="E-mail"
          />
          <RowContainer>
            <MaterialInput
              {...tel('phone')}
              disabled={loading}
              required
              label="Telefone"
              className="half"
              onChange={e =>
                formState.setField('phone', phoneMask(e.target.value))
              }
            />
            <StyledFields>
              <MaterialDatePicker
                label="Data do casamento"
                format="DD/MM/YYYY"
                value={weddingDate}
                onChange={setDate}
                disabled={dateUndefined}
                style={{ width: '100%' }}
              />
              <StyledFieldset>
                <Checkbox
                  type="checkbox"
                  name="hasDate"
                  value="Não definida"
                  onChange={() => setDateUndefined(!dateUndefined)}
                  checked={dateUndefined}
                />
                <StyledSpan>Não definida</StyledSpan>
              </StyledFieldset>
            </StyledFields>
          </RowContainer>
          <FormControl required className="half" disabled={loading}>
            <MaterialInputLabel htmlFor="region" shrink>
              Região
            </MaterialInputLabel>
            <MaterialSelect
              {...raw({
                name: 'codRegion',
              })}
              onChange={e => formState.setField('codRegion', e.target.value)}
              inputProps={{
                name: 'region',
                id: 'region',
              }}
            >
              <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
              {data.regions.edges.map(({ node: { codRegiao, Regiao } }) => (
                <MaterialMenuItem value={codRegiao} key={codRegiao}>
                  {Regiao}
                </MaterialMenuItem>
              ))}
            </MaterialSelect>
          </FormControl>
          <FormControl required className="half" disabled={loading}>
            <MaterialInputLabel htmlFor="segment" shrink>
              Segmento
            </MaterialInputLabel>
            <MaterialSelect
              {...raw({
                name: 'codSegment',
              })}
              onChange={e => formState.setField('codSegment', e.target.value)}
              inputProps={{
                name: 'segment',
                id: 'segment',
              }}
            >
              <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
              {segments.map(({ node: { codSegmento, Segmento } }) => (
                <MaterialMenuItem value={codSegmento} key={codSegmento}>
                  {Segmento}
                </MaterialMenuItem>
              ))}
            </MaterialSelect>
          </FormControl>
          {loading ? (
            <Loader />
          ) : (
            <InviteButton
              text="FINALIZAR CADASTRO"
              click={handleSubmit}
              disabled={!isClickAvaliable}
            />
          )}
        </Form>
      </CardContainer>
    </PageWrapper>
  );
};

export default IndicacaoNoivoPage;
